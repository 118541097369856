/**
 * API Documentation
 * API Documentation
 *
 * The version of the OpenAPI document: uk-cca-app-api 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ContentDisposition {
  type?: string;
  name?: string;
  filename?: string;
  charset?: string;
  size?: number;
  creationDate?: string;
  modificationDate?: string;
  readDate?: string;
  inline?: boolean;
  attachment?: boolean;
  formData?: boolean;
}
